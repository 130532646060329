import React, { Component }  from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown'
import * as QuickSightEmbedding  from 'amazon-quicksight-embedding-sdk';
import config from '../config.json'
import _ from "underscore";
import {API} from 'aws-amplify';
export default class dashboard extends Component {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            getDbList:  "",
            errors: {
              cognito: null
            }
          }        
    }    
    async componentDidMount(){
        let usrGroup;
        this._isMounted = true;
        if (this.props.auth.isAuthenticated ){
            usrGroup = this.props.auth.user.signInUserSession.accessToken.payload["cognito:groups"]
            if (usrGroup !== undefined)
            {   
                const dbGroup = await this.getDashBdList(usrGroup);
                let dbList1 =[];
                let dbList=[];
                usrGroup.forEach(usrElement => {
                    dbGroup.forEach(dbElement =>{
                        if (dbElement !== null)
                            if (usrElement===dbElement["Name"]){
                                dbElement["Dashbd"].forEach(dbs=>dbList1.push(dbs))
                            }
                    })
                });
                dbList = _.uniq(dbList1, 'dbid')
                console.log(dbList)                
                await this.setState({getDbList : dbList});        
            }
        }
    }  
    componentWillUnmount() {
        this._isMounted = false;
      }      
    getDashBdList(usrGroup){
        const apiName = config.api.name;
        const path = '/dboardlist';
        const myInit = {
            headers: { 
                 //Authorization : `Bearer ${this.props.auth.user.signInUserSession.idToken.jwtToken}`
            },
            'queryStringParameters': {
                'grpids': usrGroup.join(",")
              }            
        };
        return API.get(apiName, path, myInit);
    }
    embedDashboard = event => {
   //     event.preventDefault();
        //const cpmurl="https://cckbchlaocga.cpmbiodev.net/api/v1/cpmqs/getqsembd/?dbid=a3b01f06-735c-4535-884c-062b89bd930e"
        const cpmurl="https://cckbchlaocga.cpmbiodev.net/api/v1/cpmqs/getqsembd/?dbid=" + event
        return fetch(cpmurl, {
                headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
                },
            }).then((res) => res.json())
            .then((data) =>  {
                var containerDiv = document.getElementById("dashboardContainer");
                containerDiv.innerHTML = "";
                var options = {
                    url:data.EmbedUrl,
                    container: containerDiv,
                    scrolling: "no",
                    className: "hero  is-fullheight  dashbd"

                };
                console.log(data.EmbedUrl);
                const dashboard = QuickSightEmbedding.embedDashboard(options);
                //dashboard.on("error", onError);
                //dashboard.on("load", onDashboardLoad);

                //return data
            
            })
            .catch((err)=>console.log(err));
     }

render(){
    let addItem
    if (this.state.getDbList !=="")
        addItem = this.state.getDbList.map((name, index) => (
            <Dropdown.Item key={index} eventKey={name.dbid}> {name.title}</Dropdown.Item>
        ))
  return (  
    <div>
        {this.props.auth.isAuthenticated  && (
        <div >
                {

        <div>
            
`        <DropdownButton alignRight
                title="Select Dashboard" 
                id="dropdown-menu-align-right"
                onSelect={this.embedDashboard}>
                    { addItem}                     
                     </DropdownButton>
                </div>
                }
            <div className=" hero  is-fullheight  dashbd" id ="dashboardContainer">

            </div>  ` 
        </div>
        )}
        {!this.props.auth.isAuthenticated  && (
            <div  className="hero  is-fullheight  dashbd">
                <strong><center><p> Please login to view Dashboard.</p></center></strong>
            </div>
        )}

    </div>

  )
}
}