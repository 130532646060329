import React, { Component }  from 'react';
import $ from "jquery";
import 'bootstrap-table'
import 'bootstrap'
import '../../node_modules/bootstrap-table/dist/bootstrap-table.css'
import Button from '@material-ui/core/Button';
import saveAS from 'file-saver';
import { Auth, Storage } from 'aws-amplify';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormErrors from "./FormErrors";

export default class dataportal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selLocation: "",
            chkdFiles: "",
            chkdFileWithType :"",
            selFolder: "",
            selectedFile: "",
            level:"private",
            errors: {
              cognito: null
            }
          }
    }

      async componentDidMount(){
        try{
            this.Init();
            this._populate_s3_data();            
        }
        catch(error){
          console.log(error);
        }
      }      
      Init(){
        if (this.props.auth.user !== null)  {
            $('#selFolderNew').append($('<a id="/'+this.props.auth.user.username+'">/'+this.props.auth.user.username+'</a>'));
            let foldLink = document.getElementById("/"+this.props.auth.user.username)
            foldLink.addEventListener('click',e => this.goFolder(foldLink.id) , false)
        }
    }    
    preventDefaults (e) {
        e.preventDefault()
        e.stopPropagation()
    }

    handleFiles(filesUpload) {
        console.log(filesUpload)
        
        this.loadFilesBS(Array.prototype.slice.call(filesUpload));
    }
    loadFilesBS(filesArr){
        let _this=this;
        $('#tbluploadFiles').bootstrapTable('destroy');
        $('#tbluploadFiles').bootstrapTable({
            data: filesArr,
            columns: [
                [   
                    {
                        title: 'File Name',
                        field: 'name'
                    },
                    {
                        title: 'Size',
                        field: 'size',
                        formatter: this.bytesFormatter                                            
                    },
                    {
                        title: 'Delete',
                        formatter: function () {
                            return '<a class="delete"><span class="glyphicon glyphicon-trash"></span></a>';
                        },
                        events: {
                            'click a': function (e, value, row, index) {

                                for(let i=0;i<filesArr.length;i++) {
                                    if(filesArr[i].name === row.name) {
                                        filesArr.splice(i,1);
                                        break;
                                    }
                                }
                                _this.loadFilesBS(filesArr)
                            } 
                        }
                    }
                ]
            ]
        });
    }
     submitUpload = e => {
        try{
            let selFolder = this.state.selFolder;
            let level = this.state.level;
            if (e.isDefaultPrevented()) {
                return;
            } else {
            // everything looks good!
                let table = $('#tbluploadFiles')

                $.each(table.bootstrapTable('getData'), function(i, row) {    
                    e.preventDefault();
                    let percent = $('.percent');
                    let fileName
                    if (selFolder === "")
                        fileName =   row.name
                    else
                        fileName =   selFolder +"/"+ row.name

                    Storage.put(fileName, row, {
                        level: level,
                        contentType: row.type,
                        progressCallback(progress) {
                            percent.html(`Uploaded File : ${row.name} - ${progress.loaded}/${progress.total}`);                        
                    },
                    }).then (result => console.log(result))
                    .catch(err => console.log(err));
                });
            }
        }catch(error){
            let err = null;
            !error.messages ? err = {'messages' : error} : err = error;
            this.setState({
              errors:{
                ...this.setState.errors,
                cognito : err
              }
            });
        }
    }
    modelClose = e =>
    {
        e.preventDefault();
        this._populate_s3_data();
    }

    _resetBSModel = event => {
        event.preventDefault();
        $('#uploadStatus').empty();
        $('#fileElem').val('');
        $('#tbluploadFiles').bootstrapTable('destroy');

        let dropArea = document.getElementById('drop-area')
        let fileElem = document.getElementById('fileElem')
        console.log(dropArea)
        ;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, this.preventDefaults, false)
        })
    
        ;['dragenter', 'dragover'].forEach(eventName => {
            dropArea.addEventListener(eventName, e=>{dropArea.classList.add('highlight')}, false)
        })

        ;['dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, e=>{dropArea.classList.remove('highlight')}, false)
        })
        dropArea.addEventListener('drop', e => {this.handleFiles(e.dataTransfer.files)} , false)
        fileElem.addEventListener('change', e => {this.handleFiles(e.target.files)}, false)        
    }
    _resetCFModel(){
        $('#folderName').val('');
    }
    selectLevel = async event =>{
        event.preventDefault()
        await this.setState({
            level:event.target.value,
            selLocation: "",
            chkdFiles: "",
            chkdFileWithType :"",
            selFolder: "",
            selectedFile: "",
            errors: {
                cognito: null
              }            

        })
        let foldLink = document.getElementById("/"+this.props.auth.user.username)
        await this.goFolder(foldLink.id)
        this._populate_s3_data();
        }
    protFetch = event =>{
        event.preventDefault();
        this._populate_s3_data($("#identityId").val());
    }
    async _populate_s3_data(identId){
        if (this.props.auth.user !== null)  {
            let selFoldLen = this.state.selFolder
            let options={};
            options["level"]= $('#levelSelect').val();
            if (identId !=='' || identId !=='undefined' || identId !==null )
                options["identityId"]=identId
            await Storage.list(this.state.selFolder, options)
            .then(result => {
                console.log(result)
                let s3Res1=[]
                if (typeof result !=="undefined"){
                    $.each(result,function(keya,s3_file_value){                        
                        let slist1={}
                        let newVal
                        if (selFoldLen.length===0)
                            newVal = s3_file_value["key"].substring(selFoldLen.length)
                        else
                            newVal = s3_file_value["key"].substring(selFoldLen.length+1)                            
                        const lenSlash = newVal.split("/").length
                        if (lenSlash <= 2){
                            if (lenSlash===1 && newVal !=="")
                            {
                                slist1["File"]= newVal
                                slist1["Type"]="File";
                                slist1["Size"]=s3_file_value["size"];
                                slist1["LastModified"]=s3_file_value["lastModified"];                                        
                                s3Res1.push(slist1);                                           
                            }
                            //else if (lenSlash===2 && s3_file_value["size"] ===0){                            
                            else if (lenSlash===2){                                                            
                                if (newVal[newVal.length - 1]==="/"){
                                    slist1["File"]= newVal.slice(0, -1)
                                    slist1["Type"]="Folder";
                                    slist1["Size"]="-";
                                    slist1["LastModified"]=s3_file_value["lastModified"]; 
                                    s3Res1.push(slist1);                                                                                          
                                }
                            }
                        }
                    
                        });
                    }
                console.log(s3Res1)    
                this.builds3Table(s3Res1);
            })
            .catch(error => 
                {
                    let err = null;
                    !error.messages ? err = {'messages' : error} : err = error;
                    this.setState({
                      errors:{
                        ...this.setState.errors,
                        cognito : err
                      }
                    });
                    console.log(error)
                });
        }
    }
     bytesFormatter(value, row, index) {
        if (value==="-")
            return "-"
        else
            if(value === 0) return '0 Bytes';
            let k = 1024,
                dm = 2,
                sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                i = Math.floor(Math.log(value) / Math.log(k));
            return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    onDownload = event => 
    {
        console.log(this.state.chkdFiles)
        let arrChkdFiles = this.state.chkdFiles.split(",");
        for (const element of arrChkdFiles) {
            if (element !== "")
            {
                event.preventDefault()
                let fileName
                if (this.state.selFolder === "")
                    fileName =   encodeURIComponent(element)
                else
                    fileName =   this.state.selFolder +"/"+ encodeURIComponent(element)
        
                Storage.get(fileName,  {
                level: this.state.level
                })
                .then (result => {
                    console.log(result)
                    fetch(result)
                    .then( res => res.blob() )
                    .then( blob => {
                        saveAS(blob, encodeURIComponent(element))
                    });
                })
                .catch(error => 
                    {
                        let err = null;
                        !error.messages ? err = {'messages' : error} : err = error;
                        this.setState({
                          errors:{
                            ...this.setState.errors,
                            cognito : err
                          }
                        });                        
                        console.log(error)
                    });
            }

          }

    }
    onDelete = async event => {
        event.preventDefault();
        let arrChkdFileWithType = this.state.chkdFileWithType.split(",");
        console.log(arrChkdFileWithType)
        for (const element of arrChkdFileWithType) {
            //event.persist()
            if (element !== "")
            {
                const arrElem = element.split("/")
                let newElem;
                if (arrElem[0]==="File")
                    newElem=arrElem[1]
                else
                    newElem=arrElem[1] +"/"

                let fileName
                if (this.state.selFolder === "")
                    fileName =   (newElem)
                else
                    fileName =   this.state.selFolder +"/"+ (newElem)
        
                await Storage.remove(fileName,  {
                level: this.state.level
                })
                .then (result => console.log(result))
                .catch(error => 
                    {
                        let err = null;
                        !error.messages ? err = {'messages' : error} : err = error;
                        this.setState({
                          errors:{
                            ...this.setState.errors,
                            cognito : err
                          }
                        });
                        console.log(error)
                });
            }

          }
        this._populate_s3_data();
    }
    onCreateFolder = async event => 
    {
        event.preventDefault();
        let selFolder = this.state.selFolder        
        let fileName
        if (selFolder === "")
            fileName =   $('#folderName').val()
        else
            fileName =   selFolder +"/"+$('#folderName').val()

        await Storage.put(fileName+"/",'Folder', {
            level: this.state.level
,        }).then (result => console.log(result))
        .catch(error => 
            {
                let err = null;
                !error.messages ? err = {'messages' : error} : err = error;
                this.setState({
                  errors:{
                    ...this.setState.errors,
                    cognito : err
                  }
                });
                console.log(error)
        });
        this._populate_s3_data();
    }
    onRefresh = event =>{
        event.preventDefault();
        this._populate_s3_data($("#identityId").val());
    }   
    _restCallPost(awss3url,opType,fd){
        if (awss3url!==""){
            $.extend({
                getValues: function(url) {
                    let s3Res;
                    $.ajax({ 
                        type: 'POST',
                        url: awss3url,
                        data: fd,
                        async:false,
                        enctype: "multipart/form-data",
                        processData: false,
                        contentType: false,
                        success: function(data){
                            s3Res=data;
                        },
                    error: function (xhr, errorType, exception) {
                        alert("Excep:: " + exception + "Status:: " + xhr.statusText);
                    }    
                    });
                return s3Res;
                }
            });
            return $.getValues();
        }
        else   
            return;
    } 

    _restCall(awss3url){
        if (awss3url!==""){
            $.extend({
                getValues: function(url) {
                    let s3Res;
                    $.ajax({ 
                        type: 'GET',
                        url: awss3url,
                        dataType: "json",
                        async: false,
                        timeout: 3000,
                        success: function(data){
                        s3Res=data;
                    },
                    error: function (xhr, errorType, exception) {
                        //alert("Excep:: " + exception + "Status:: " + xhr.statusText);
                    }    
                    });
                return s3Res;
                }
            });
            return $.getValues();
        }
        else   
            return;
    } 
    
    fileFormatter(value, row, index) {
        if (row.Type === "Folder") {
            return "<a>" + value + "</a>";

        } else {
            return value;
        }
    }
    async goFolder(id){
        let existingdiv1 = document.getElementById(id);
        $(existingdiv1).nextAll().remove();
        await this.setState({selLocation : id});
        $('#location').text(id);
        let selLocation = this.state.selLocation
        let myarray = selLocation.split("/")
            //remove first two elements from array
        myarray.shift();
        myarray.shift();
        await this.setState({selFolder:myarray.join("/")});
        this._populate_s3_data($("#identityId").val());

    }
    async _navFolder(row){
        if (row.Type==="Folder"){
            //let getLocPath =$('#location').text();
            let getLocPath = $('#location').text();
            await this.setState({selLocation:getLocPath + "/" + row.File})
            $('#location').text(this.state.selLocation);
            //code added newly
            $('#selFolderNew').append($('<a id="'+this.state.selLocation+'">->'+row.File+'</a>'));
            let foldLink = document.getElementById(this.state.selLocation)
            foldLink.addEventListener('click',e => this.goFolder(foldLink.id) , false)

            //code completed by ananth
            let strPath = getLocPath + "/" + row.File
            let myarray = strPath.split("/")
            //remove first two elements from array
            myarray.shift();
            myarray.shift();
            await this.setState({selFolder:myarray.join("/")})
            this._populate_s3_data($("#identityId").val());
        }
    }  
    async getEssentialCredential(){
        await Auth.currentCredentials()
        .then(credentials => {
          // access keys available on:
          let creds = Auth.essentialCredentials(credentials)
          let html =``
          html+=`<b>Credential Information for Identity Id : ${creds["identityId"]}</b>`
          html+=`<h3><b>Linux</b></h3>`
          html+=`<p>export AWS_ACCESS_KEY_ID=${creds['accessKeyId']}</p>`
          html+=`<p>export AWS_SECRET_ACCESS_KEY=${creds['secretAccessKey']}</p>`
          html+=`<p>export AWS_SESSION_TOKEN=${creds['sessionToken']}</p>`                    
          html+=`<h3><b>Windows</b></h3>`
          html+=`<p>SET AWS_ACCESS_KEY_ID=${creds['accessKeyId']}</p>`
          html+=`<p>SET AWS_SECRET_ACCESS_KEY=${creds['secretAccessKey']}</p>`
          html+=`<p>SET AWS_SESSION_TOKEN=${creds['sessionToken']}</p>`                              
          

          $('#credModalBody').html(html)
          console.log(creds)
        })
    }

    onDownloadLink = async event =>{
        event.preventDefault()
        let arrChkdFiles = this.state.chkdFiles.split(",");
        let html =``
        for (const element of arrChkdFiles) {
            if (element !== "")
            {
                let fileName
                if (this.state.selFolder === "")
                    fileName =   encodeURIComponent(element)
                else
                    fileName =   this.state.selFolder +"/"+ encodeURIComponent(element)
        
                const res = await Storage.get(fileName,  {
                level: this.state.level
                })
                .then (result => {
                    return result
                    })
                .catch(error => 
                    {
                        let err = null;
                        !error.messages ? err = {'messages' : error} : err = error;
                        this.setState({
                          errors:{
                            ...this.setState.errors,
                            cognito : err
                          }
                        });
                        console.log(error)
                });
                console.log(res)
                html += `<a>${res}</a><br><br>` 
            }

          }        
          $('#downldLinkModalBody').html(html)                    



    }
    builds3Table(s3Data){
        let _this = this;
        let _files=[];
        let _filesWithType=[];
        $('#s3optable').bootstrapTable('destroy');
        $('#s3optable').bootstrapTable({
            data: s3Data,
            columns: [
                [
                    {
                        field: {source: 'state', context: this},
                        checkbox: true,
                        // formatter: this.stateFormatter
                    },
                    {
                        title: 'File Name',
                        field: 'File',
                        formatter: this.fileFormatter,
                        events:{
                            'click a': function (e, value, row, index) {
                                _this._navFolder(row);
                            }
                        }
                    },
                    {
                        title: 'File Type',
                        field: 'Type'
                    },

                    {
                        title: 'LastModified',
                        field: 'LastModified'                                            
                    },
                    {
                        title: 'Size',
                        field: 'Size',
                        formatter: this.bytesFormatter                                            
                    }
                ]
            ],
            onCheck: function (row, elem) {
                _files.push(row.File);
                _this.setState({chkdFiles:_files.join()})
                //_this.chkdFiles = _files.join();
                
                _filesWithType.push(row.Type + "/" + row.File);
                _this.setState({chkdFileWithType:_filesWithType.join()})
            },
            onCheckAll: function (rows) {
                _files=[];
                rows.forEach(function(entry) {
                    _files.push(entry.File);
                });
                _this.setState({chkdFiles:_files.join()})
                //_this.chkdFiles = _files.join();

                _filesWithType=[];
                rows.forEach(function(entry) {
                    _filesWithType.push(entry.Type +"/"+ entry.File);
                });
                _this.setState({chkdFileWithType:_filesWithType.join()})                


            },                
            onUncheckAll: function (rows) {
                //_this.chkdFiles = undefined;
                _this.setState({chkdFiles:""})
                _this.setState({chkdFileWithType:""})
            },
            onUncheck: function (row, elem) {
                let fileToDeleteIdx = -1;
                for (let i in _files) {
                    if (_files[i] === row.File) {
                        fileToDeleteIdx = i;
                        break;
                    }
                }

                if (fileToDeleteIdx === -1) {
                    return;
                }
                _files.splice(fileToDeleteIdx,1)
                //_this.chkdFiles = _files.join();
                _this.setState({chkdFiles:_files.join()})


                let fileToDeleteIdxN = -1;
                for (let i in _filesWithType) {
                    if (_filesWithType[i] === row.Type +"/"+ row.File) {
                        fileToDeleteIdxN = i;
                        break;
                    }
                }

                if (fileToDeleteIdxN === -1) {
                    return;
                }
                _filesWithType.splice(fileToDeleteIdxN,1)
                //_this.chkdFiles = _files.join();
                _this.setState({chkdFileWithType:_filesWithType.join()})                

            }, 
            onDblClickRow: function (row, element, field) {
                _this._navFolder(row);
            },
            onClickRow: function (row, element, field) {
                $(element).addClass('success').siblings().removeClass('success');
                if (row.Type==="File")
                    _this.setState({selectedFile:row.File})
                    //_this.selectedFile=row.File;
                else    
                    //_this.selectedFile=undefined;
                    _this.setState({selectedFile:undefined})
            }               
        });
    }                        

render(){
  return (  
    <div  className="App" >
        {this.props.auth.isAuthenticated  && (
        <div className=" hero  is-fullheight">
          <div id="s3operations" style = {{marginTop: '10px'}} >
              <form id="formAwsOp" data-toggle="validator" className="form-horizontal">
                  <div className="panel" style={{marginBottom: '15px'}}>
                  <NativeSelect color="primary"
                    id ="levelSelect"
                    value={this.state.level}
                    onChange={this.selectLevel}>
                    <option value={"private"}>Private</option>
                    <option value={"protected"}>Protected</option>
                    <option value={"public"}>Public</option>
                  </NativeSelect>

                    <Button color="primary" data-toggle="modal" data-target="#uploadModal" onClick = {this._resetBSModel}>
                          <i className="fa fa-upload"></i>  Upload
                    </Button>
                  <Button color="primary" onClick={this.onDownload}>
                          <i className="fa fa-download"></i> Download
                  </Button>
                  <Button color="primary" data-toggle="collapse" data-target="#createFolderArea">
                          <i className="fa fa-folder"></i> Create Folder
                  </Button>           
                  <Button color="primary" onClick={this.onDelete}>
                      <i className="fa fa-trash"></i> Delete
                  </Button>    
                  <Button color="primary" onClick= {this.onRefresh}>
                      <i className="fa fa-refresh"></i> Refresh
                  </Button>                            
                  <Button color="primary" data-toggle ="modal" data-target ="#credDispModal" onClick= {this.getEssentialCredential}>
                      Temp. Credential
                  </Button>                                              
                  <Button color="primary" data-toggle ="modal" data-target ="#downldLinkModal" onClick= {this.onDownloadLink}>
                      Download Link
                  </Button>                                                                
                  { this.state.level === 'protected' &&
                    <div>
                        <label>Identity Id  :</label>
                        <input type="text" id="identityId"></input>
                        <Button color="primary"  onClick={this.protFetch}>
                          <i className="fa fa-search"></i> Fetch 
                        </Button>                                   
                    </div>    
        }


                  <div id="selFolder" className="panel" style={{marginBottom: '15px'}} hidden>
                        <a id="location">
                            /{this.props.auth.user.username}
                        </a>
                    </div>
                   <div id="selFolderNew" className="panel"  style= {{backgroundColor: '#eee', color: 'blue' }}>

                   </div>
                   <FormErrors formerrors={this.state.errors} />                                         
                  </div>
                  <div id="createFolderArea" className="collapse">
                          <br></br><br></br>
                      <label htmlFor="usr">Folder Name:  </label>
                      <input type="text" id="folderName"></input>
                      <button type="button" className="btn btn-primary" onClick= {this.onCreateFolder}>
                              <i className="fa fa-save"></i> Save
                      </button> 
                      <button type="button" className="btn btn-primary" onClick= {this._resetCFModel}>
                          Clear
                      </button>                     
                  </div>                 
                  <table id="s3optable" data-search="true" data-show-columns="true" data-pagination="true" data-page-list="[10, 25, 50]"
                      data-show-pagination-switch="true" data-show-export="true">
                      <thead style= {{backgroundColor: '#eee'}}></thead>
                  </table>
              

              </form>    
          </div>
        {/*File upload model dialog */}
          <div className="modal fade" id="uploadModal" tabIndex="-1" role="dialog" aria-labelledby="uploadModalLabel" aria-hidden="true">
              <form id="formUpload" data-toggle="validator" className="form-horizontal" onSubmit={this.submitUpload}>
                  <div className="modal-dialog modal-lg" role="document">
                      <div className="modal-content">
                          <div className="modal-header">
                          <h3 className="modal-title" id="uploadModalLabel">Upload</h3>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                          </button>
                          </div>
                          <div className="modal-body" id ="uploadModalBody">
                              <div id="drop-area">
                                <input type="file" id="fileElem"  className="form-control-file" multiple  ></input>
                                <label className="button" htmlFor="fileElem">Drag and Drop / Select files</label>
                                <p id ="selFiles"></p>

                                <table id="tbluploadFiles" data-toggle="table"data-height="428">
                                    <thead style={{backgroundColor: '#eee'}}></thead>
                                  </table>                            
                              </div>
                              <div style ={{height:'50px'}}>
                                  <div id="uploadStatus border" className="percent" style={{fontSize:'20'}}>
                                   </div >
                              </div>

                          </div>
                          <div className="modal-footer">
                                {/*<button type="button" className="btn btn-primary" onClick= {this.submitUpload}>
                                    <i className="fa fa-save"></i> Upload
                </button>                               */}
                              <button type="submit" className="btn btn-primary">Upload</button>
                              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick ={this.modelClose}>Close</button>
                          </div>
                      </div>
                  </div>
              </form>
              </div>

        {/*Credential info model dialog */}
        <div className="modal fade" id="credDispModal" tabIndex="-1" role="dialog" aria-labelledby="credDispModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header" id="credModalLabel">
                        <h3>Temporary Credentials Information for this user session</h3>
                    </div>
                    <div className="modal-body" id ="credModalBody">

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        {/*Download link model dialog */}
        <div className="modal fade" id="downldLinkModal" tabIndex="-1" role="dialog" aria-labelledby="downloadLinkLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header" id="credModalLabel">
                        <h3>Download Link</h3>
                    </div>
                    <div className="modal-body" id ="downldLinkModalBody">

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>



        </div>
        )}
        {!this.props.auth.isAuthenticated  && (
            <div  className="hero  is-fullheight  dashbd">
                <strong><center><p> Please login to view Data Portal.</p></center></strong>
            </div>
        )}

    </div>

  )
}
}
                        