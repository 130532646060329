import React from 'react'

export default function HomeContent() {
  return (
    <section className="container">
        <div className="columns features">
            <div className="column is-4">
                <div className="card is-shady">
                    <div className="card-content">
                        <div className="content">
                            <h4>Dashboard View</h4>
                            <p>A dashboard is a type of graphical user interface which often provides at-a-glance views of key performance indicators (KPIs) relevant to a particular objective or business process. ... The "dashboard" is often displayed on a web page which is linked to a database that allows the report to be constantly updated.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column is-4">
                <div className="card is-shady">
                    <div className="card-content">
                        <div className="content">
                            <h4>Table View</h4>
                            <p>A table view displays a single column of vertically scrolling content, divided into rows and sections. ... Sections let you group related rows together. For example, the Contacts app uses a table to display the names of the user's contacts. Table views are a collaboration between many different objects, including: Cells.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column is-4">
                <div className="card is-shady">
                     <div className="card-content">
                        <div className="content">
                            <h4>phylogenetic View</h4>
                            <p>Phylogenetic tree, a diagram showing the evolutionary interrelations of a group of organisms derived from a common ancestral form. The ancestor is in the tree “trunk”; organisms that have arisen from it are placed at the ends of tree “branches.” The distance of one group from the other groups indicates the degree of relationship.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
