import React from 'react';
import ReactDOM from 'react-dom';
import 'bulma/css/bulma.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import config from './config'
Amplify.configure({
Auth:{
    mandatorySignId : true,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID    
},
Storage: {
    AWSS3: {
        bucket: config.cognito.BUCKET, //REQUIRED -  Amazon S3 bucket
        region:  config.cognito.REGION
    }
},
API: {
    endpoints: [
        {
            name: config.api.name,
            endpoint: config.api.invokeUrl
        }
    ]
}
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();